/**======================================================================
=========================================================================
Template Name: Dashboard Bootstrap 5 Admin Template
Author: Rajodiya Infotech
Support: [support link]
File: style.css
=========================================================================
=================================================================================== */
// main framework
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables";

body {
  margin: 0;
  overflow-x: hidden;
  background: lighten($body-bg, 5%);
}

a {
  outline: none;
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
  }
}

.pct-customizer {
  // display: none;
}

section {
  padding-top: 90px;
  padding-bottom: 90px;
}

.bg-dark {
  background: $dark !important;
  color: #fff;

  p {
    color: #b5bdca;
  }

  .title {
    h2 {
      color: transparentize(#fff, 0.3);

      span {
        color: #fff;
      }
    }
  }
}

.theme-alt-bg {
  background: #ededed;
}

.title {
  position: relative;
  text-align: center;
  margin-bottom: 50px;

  h2 {
    margin-bottom: 15px;
    z-index: 3;
    color: $body-color;
    margin-top: 15px;
    text-transform: capitalize;

    span {
      font-weight: 600;
      color: $headings-color;
    }
  }

  .material-icons-two-tone {
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 30px;

    h2 {
      margin-bottom: 10px;
    }
  }
}
// [ navbar ] Start

.navbar {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 70px;
  top: -80px;
  backdrop-filter: blur(5px);
  // background: rgba(22, 28, 37, 0.9);
  transition: all 0.3s linear;
  margin-bottom: 0;
  box-shadow: 0 8px 6px -10px rgba(0, 0, 0, 0.5);

  &.default {
    top: 0;
    background: transparentize($dark, 0.1%);
  }
  &.default.top-nav-collapse {
    background: transparent;
    box-shadow: none;
  }
  @media (min-width: 767px) {
    .navbar-nav {
      .nav-link {
        position: relative;

        // &:after {
        //   content: "";
        //   position: absolute;
        //   height: 3px;
        //   bottom: 0;
        //   background: $primary;
        //   left: 50%;
        //   right: 50%;
        // }

        &.active {
          &:after {
            left: 15%;
            right: 15%;
          }
        }
      }
    }
  }
}
// [ navṇbar ] end
// [ header ] Start

header:not(.price-header) {
  position: relative;
  color: #fff;
  min-height: 60vh;
  padding-top: 160px;
  padding-bottom: 90px;
  display: flex;
  align-items: center;

  > * {
    position: relative;
    z-index: 5;
  }
  &:after,
  &:before {
    content: "";
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:before {
    background-attachment: fixed;
    z-index: 1;
  }

  // &:after {
  // background-color: transparentize($primary, 0);
  // z-index: 2;
  // }
}
header.price-header {
  position: relative;
  min-height: 60vh;
  padding-top: 160px;
  padding-bottom: 90px;
  display: flex;
  align-items: center;
}
// [ header ] end
.dashboard-block {
  position: relative;
  overflow: hidden;
  .img-dashboard {
    margin-bottom: -15%;
  }
}
.feature {
  text-align: center;
  .card-body {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .theme-avtar {
    width: 130px;
    height: 130px;
    font-size: 45px;
    margin: 0 auto;
    border-radius: 50px;
  }
}
.price-section {
  position: relative;
  > * {
    position: relative;
    z-index: 5;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50%;
    z-index: 1;
    background: linear-gradient(180deg, #cecece 0%, #ffffff 100%);
  }
}
.faq {
  .accordion {
    .accordion-item {
      border-radius: $border-radius;
      overflow: hidden;
      border: none;
      margin-bottom: 10px;
      .accordion-button {
        font-weight: 700;
        padding: 1.3rem 1.25rem;
        span > i {
          font-size: 20px;
          margin-right: 8px;
        }
        &:not(.collapsed) {
          border-radius: $border-radius;
          background: transparent;
          box-shadow: $card-shadow;
        }
      }
      .accordion-body {
        padding: 2.3rem 2.3rem 2.3rem 3rem;
      }
    }
  }
}
.price-card {
  text-align: center;
  position: relative;
  margin-top: 30px;
  &.price-1 {
    // background: #808191;
    // color: #fff;
  }
  &.price-2 {
    // background: linear-gradient(
    //     141.55deg,
    //     rgba(81, 69, 157, 0) 3.46%,
    //     rgba(255, 58, 110, 0.6) 99.86%
    //   ),
    //   #51459d;
    color: #fff;
    .price-badge {
      color: #fff;
      background: $dark;
    }
  }
  &.price-3 {
    // background: #cecece;
  }
  .p-price {
    font-size: 80px;
  }
  .price-badge {
    color: #fff;
    padding: 7px 24px;
    border-radius: 30px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .list-unstyled {
    display: inline-block;
    li {
      display: flex;
      align-items: center;
      + li {
        margin-top: 8px;
      }
    }
    .theme-avtar {
      display: inline-flex;
      width: 30px;
      height: 30px;
      border-radius: 10px;
      background: #fff;
      margin-right: 15px;
    }
  }
}
.side-feature {
  overflow: hidden;
  .feature-img-row {
    width: 80vw;
  }
}
@media only screen and (max-width: 992px) {
  header,
  section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .navbar {
    height: auto;
  }
}
